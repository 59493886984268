import React from "react";
import { ModalTemplate } from "@atomic";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";

export enum DownloadOptions {
  EXCEL = "EXCEL",
  PDF = "PDF",
}

interface IProps {
  isDownloadModalOpen: boolean;
  setIsDownloadModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedSiteId: string;
}

export const EpointageDownloadModal: React.FC<IProps> = ({
  isDownloadModalOpen,
  setIsDownloadModalOpen,
  selectedSiteId
}) => {
  const [downloadOption, setDownloadOption] = React.useState<string>();
  const [step, setStep] = React.useState<number>(1);

  const handleNextStep = () => {
    if (!!downloadOption) setStep(2);
  };

  const handleGoBack = () => {
    setStep(1);
  };

  React.useEffect(() => {
    if (!isDownloadModalOpen && !!downloadOption) {
      setDownloadOption(undefined);
      setStep(1);
    }
  }, [isDownloadModalOpen, downloadOption]);

  return (
    <ModalTemplate
      isOpen={isDownloadModalOpen}
      handleClose={() => setIsDownloadModalOpen(false)}
      isFullScreen
      closeOnClickOutside
      isCenter
      style={{
        flex: 1,
        width: "max-content",
        maxWidth: 470,
        paddingHorizontal: step === 2 ? 30 : 70,
        paddingVertical: step === 2 ? 20 : 30,
        overflow: "visible",
      }}
    >
      {step === 1 ? (
        <Step1
          handleNextStep={handleNextStep}
          downloadOption={downloadOption}
          setDownloadOption={setDownloadOption}
        />
      ) : (
        <Step2 downloadOption={downloadOption} handleGoBack={handleGoBack} siteId={selectedSiteId}/>
      )}
    </ModalTemplate>
  );
};
