import React from "react";
import { View, StyleSheet, Pressable } from "react-native";
import { Colors } from "@constants";
import {
  ScheduleTimeline,
  SchedulerViewModel,
  SchedulersWithDynamicKeys,
  Schedules,
} from "@modules";
import { useDispatch } from "react-redux";
import {
  EPointageClassDetailSchedule,
  EPointageClassDetailStudent,
} from "@atomic/molecules";
import { Title16 } from "@stylesheets";
import { User } from "@assets";
import { Icon } from "@atomic";
import { EpointageThunks } from "@modules";
import moment from "moment";
import { LoaderActions } from "@redux";

interface IProps {
  serviceId: number;
  classroomId: string;
  schoolId: string;
  siteId: string;
  schedulerMapped?: SchedulersWithDynamicKeys;
  schedules: Schedules[];
  closeDetail: () => void;
  selectedDate: string;
  userId: string;
  userRole: string;
  handleSchedulerInfo: () => void;
}

export const EpointageClassDetail: React.FC<IProps> = ({
  classroomId,
  serviceId,
  schoolId,
  schedulerMapped,
  schedules,
  closeDetail,
  selectedDate,
  userId,
  userRole,
  handleSchedulerInfo,
  siteId
}) => {
  const dispatch = useDispatch();
  const [schedulerVM] = React.useState<SchedulerViewModel>(
    new SchedulerViewModel(dispatch)
  );

  const haveWritePermission = schedulerVM.checkIfHaveWritePermission(userRole);

  const initialTotalChecked = schedules?.reduce(
    (total: number[], s: Schedules) => {
      const t =
        s?.schedulerTimeline !== ScheduleTimeline.FUTURE_SCHEDULE
          ? s.students.length
          : 0;
      total.push(t);
      return total;
    },
    []
  );

  const [totalChecked, setTotalChecked] = React.useState(initialTotalChecked);

  const currentClassDetail = schedulerVM.getMappedClassDetail(
    serviceId,
    classroomId,
    schedulerMapped || {}
  );
  const filteredComment = schedulerVM.getCommentWithServiceId(
    currentClassDetail?.comments,
    serviceId
  );
  const studentsArray = schedulerVM.getStudentsFromSchedule(schedules[0]);

  const handleTotalUpdate = (total: number, index: number) => {
    const newTotalChecked = [...totalChecked];
    newTotalChecked[index] = total;
    setTotalChecked(newTotalChecked);
  };

  const submitComment = async (comment: string) => {
    dispatch(LoaderActions.setLoading(true));

    const isEdit = !!filteredComment?.updateDate;
    const updateUserId = parseInt(userId);

    const commentInput = {
      siteId,
      etaId: schoolId,
      date: selectedDate,
      claId: classroomId,
      scheduleId: serviceId,
      updateUser: isNaN(updateUserId) ? 999 : updateUserId,
      updateDate: moment().format("YYYY-MM-DD"),
      comment: comment,
    };

    try {
      isEdit
        ? await dispatch(EpointageThunks.updateEpointageComment(commentInput))
        : await dispatch(EpointageThunks.addEpointageComment(commentInput));
    } catch (error) {
    } finally {
      handleSchedulerInfo();
    }
  };

  return (
    <View style={styles.mainContainer}>
      <Pressable style={styles.headerContainer} onPress={closeDetail}>
        <View style={styles.nameWrapper}>
          <Title16 bold>{currentClassDetail.name}</Title16>
        </View>
        {totalChecked?.map((total: number, index: number) => (
          <View key={index} style={styles.sectionWrapper}>
            <User />
            <Title16 bold>{total}</Title16>
          </View>
        ))}
        <View style={styles.arrowWrapper}>
          <Icon
            size={12}
            color={Colors.foodiBlack}
            name="Accordeon_arrow_open_black"
          />
        </View>
      </Pressable>
      <View style={styles.classDetailWrapper}>
        <View style={styles.classDetailContainer}>
          <EPointageClassDetailStudent
            studentList={studentsArray}
            submitComment={submitComment}
            classComments={filteredComment?.comment || ""}
            haveWritePermission={haveWritePermission}
          />
          {schedules.map((schedule: Schedules, index: number) => {
            const prevSchedule =
              index > 0 ? schedules[index - 1] : schedules[0];
            return (
              <EPointageClassDetailSchedule
                key={index}
                scheduleIndex={index}
                schedule={schedule}
                prevschedule={prevSchedule}
                updateTotalChecks={(total) => handleTotalUpdate(total, index)}
                selectedDate={selectedDate}
                userId={userId}
                siteId={siteId}
                handleSchedulerInfo={handleSchedulerInfo}
                haveWritePermission={haveWritePermission}
              />
            );
          })}
        </View>
      </View>
      <Pressable style={styles.footerContainer} onPress={closeDetail}>
        <View style={styles.arrowWrapper}>
          <Icon
            size={12}
            color={Colors.foodiBlack}
            name="Accordeon_arrow_open_black"
          />
        </View>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    width: "100%",
    flexDirection: "column",
    alignSelf: "flex-start",
    alignContent: "flex-start",
  },
  classDetailWrapper: {
    paddingHorizontal: 20,
    borderColor: Colors.disabledBackground,
    backgroundColor: Colors.white,
    borderLeftWidth: 1,
    borderRightWidth: 1,
  },
  classDetailContainer: {
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  footerContainer: {
    height: 35,
    backgroundColor: Colors.disabledBackground,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderColor: Colors.disabledBackground,
    borderWidth: 1,
  },
  headerContainer: {
    flex: 1,
    flexDirection: "row",
    marginTop: 20,
    paddingHorizontal: 20,
    paddingVertical: 14,
    height: 46,
    backgroundColor: Colors.foodiDefault,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderColor: Colors.foodiDefault,
    borderWidth: 1,
  },
  sectionWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "row",
  },
  nameWrapper: {
    flex: 1,
    maxWidth: 310,
    justifyContent: "center",
    alignItems: "flex-start",
  },
  arrowWrapper: {
    position: "absolute",
    right: 20,
    alignSelf: "center",
  },
});
